import { all, call, fork, takeEvery, put } from 'redux-saga/effects';
import {
  GET_SITE_INFO,
  GET_SITE_INFO_SUCCESS,
  GET_SITE_INFO_FAILURE,
  GET_SITE_INFO_LOADING,
} from './constants';
import { db, auth, functions } from '../../firebase';
import { getSiteDataSuccess } from './actions';

const getSiteData = async () => {
  const siteRef = db.collection('settings').doc('site')
  const siteData = await siteRef.get()
  return siteData.data()
}

function* getSiteInfo() {
  const doc = yield call(getSiteData);
  yield put(getSiteDataSuccess(doc));
}

export function* getSiteInfoSaga() {
  yield takeEvery(GET_SITE_INFO, getSiteInfo);
}


function* siteSaga() {
  yield all([fork(getSiteInfoSaga)])
}

export default siteSaga;
