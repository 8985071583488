import {
  GET_ALL_USER_INFO,
  GET_ALL_USER_INFO_SUCCESS,
  GET_ALL_USER_INFO_FAILED,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAILED,
  UPDATE_USER_INFO,
} from './constants';

import { db, auth, functions } from '../../firebase';
import { 
  getAllUsers, getAllUsersFailed, getAllUsersSuccess, 
  createNewUser, createNewUserFailed, createNewUserSuccess,
} from './actions';

import { fetchJSON } from '../../helpers/api';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

function* getUsers({ payload: { token } }) {

  const funcCall = functions.httpsCallable('listUsers');
  const usersAll = yield call(funcCall);
  yield put(getAllUsersSuccess(usersAll.data.data || []));
}


function* createNewUserSaga({ payload: { values } }) {
  
  const { email, password, displayName, role } = values;
  const funcCall = functions.httpsCallable('createUser');
  let called = null;
  try {
    called = yield call(funcCall, values);
  } catch (error) {
    yield put(createNewUserFailed(error.message));
    return;
  }

  
  if (called.data.status === 200) {
    yield put(createNewUserSuccess());
  } else {
    yield put(createNewUserFailed(called.data.message));
  }

  
}

export function* watchGetAllUsers() {
  yield takeEvery(GET_ALL_USER_INFO, getUsers);
}

export function* watchCreateNewUser() {
  yield takeEvery(CREATE_NEW_USER, createNewUserSaga);
}

function* usersSaga() {
  yield all([fork(watchGetAllUsers), fork(watchCreateNewUser)]);
}

export default usersSaga;
