import {
  GET_ALL_USER_INFO,
  GET_ALL_USER_INFO_SUCCESS,
  GET_ALL_USER_INFO_FAILED,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAILED,
  UPDATE_USER,
} from './constants';

const initialState = {
  users: [],
  loading: false,
  error: null,
  created: false
};

const User = (state = initialState, action) => {

  switch (action.type) {
    case GET_ALL_USER_INFO:
      return {
        ...state,
        loading: true
      };
    case GET_ALL_USER_INFO_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null
      };
    case GET_ALL_USER_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        users: []
      };
    case CREATE_NEW_USER:
      return {
        ...state,
        loading: true,
        created: false
      };
    case CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        created: true
      };
    case CREATE_NEW_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        created: false
      };
    default:
      return state;
  }
}

export default User;