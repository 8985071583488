import {
  GET_SITE_INFO,
  GET_SITE_INFO_SUCCESS,
  GET_SITE_INFO_FAILURE,
  GET_SITE_INFO_LOADING,
} from './constants';

const site = {
  loading: false,
  clients: '',
  clientsActive: '',
  clientsInactive: '',
}

const Site = (state = site, action) => {
  switch (action.type) {
    case GET_SITE_INFO:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case GET_SITE_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        loading: false,
      };
    case GET_SITE_INFO_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_SITE_INFO_LOADING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default Site;