// src/firebase.js

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { Cookies } from 'react-cookie';

// TODO: Use a configuration object
firebase.initializeApp({
  // projectId: '',
  // appId: '',
  // databaseURL: '',
  // storageBucket: '',
  // locationId: '',
  // apiKey: '',
  // authDomain: '',
  // messagingSenderId: '',
  apiKey: "AIzaSyCG8uvJT-J1P0T0-oBMpKaJgS4I65CeK9Y",
  authDomain: "test-react-project-3987f.firebaseapp.com",
  projectId: "test-react-project-3987f",
  storageBucket: "test-react-project-3987f.appspot.com",
  messagingSenderId: "6523288238",
  appId: "1:6523288238:web:747528dd5f8314cfcacb3f",
  measurementId: "G-GTVTJGBP6E"
});

const db = firebase.firestore();
const auth = firebase.auth;
const functions = firebase.functions();

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  db.useEmulator('localhost', 8080);
  auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
  functions.useEmulator("localhost", 5001);
}

auth().onAuthStateChanged((user) => {
  console.log('CHANGE USER AUT')
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    var uid = user.uid;
    // ...
    // console.log('user; ', user);
  } else {
    // User is signed out
    // ...
    console.log('no esta signed');
    let cookies = new Cookies();
    cookies.remove('user', { path: '/' });
    // check if url is in the signup url and redirect to the login page
    if (window.location.pathname !== '/account/login') {
      window.location.href = '/account/login';
    }
  }
});

export default firebase;
export { db, auth, functions };