// @flow
import {
  GET_ALL_USER_INFO,
  GET_ALL_USER_INFO_SUCCESS,
  GET_ALL_USER_INFO_FAILED,
  CREATE_NEW_USER,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAILED,
  UPDATE_USER_INFO,
} from './constants';

export const getAllUsers = () => ({
  type: GET_ALL_USER_INFO,
  payload: {}
});

export const getAllUsersSuccess = (users) => ({
  type: GET_ALL_USER_INFO_SUCCESS,
  payload: users
});

export const getAllUsersFailed = (error) => ({
  type: GET_ALL_USER_INFO_FAILED,
  payload: error
});

export const createNewUser = (values) => ({
  type: CREATE_NEW_USER,
  payload: { values }
});

export const createNewUserSuccess = (user) => ({
  type: CREATE_NEW_USER_SUCCESS,
  payload: user
});

export const createNewUserFailed = (error) => ({
  type: CREATE_NEW_USER_FAILED,
  payload: error
});