import {
  GET_SITE_INFO,
  GET_SITE_INFO_SUCCESS,
  GET_SITE_INFO_FAILURE,
  GET_SITE_INFO_LOADING,
} from './constants';


export const getSiteData = () => ({
  type: GET_SITE_INFO,
  payload: {},
});

export const getSiteDataSuccess = (data) => ({
  type: GET_SITE_INFO_SUCCESS,
  payload: data,
});

export const getSiteDataFailure = (error) => ({
  type: GET_SITE_INFO_FAILURE,
  payload: error,
});

